<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <PageHeader />
        
      </el-header>
      <el-divider />
      <el-container>
        <el-aside width="160px">
          <!-- :activeNav="activeNav" -->
          <NavBar :asideWidth="asideWidth"  @nav-click="handleNavClick" />
        </el-aside>
        <el-main>
          <router-view />  
          <!-- :data="filteredData" :pageSize="pageSize" :currentPage="currentPage" :total="total" @page-change="handlePageChange" -->
          <!-- <DataDisplay :data="filteredData" :pageSize="pageSize" :currentPage="currentPage" :total="total" @page-change="handlePageChange" /> -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script>
// 引入必要的组件  
import PageHeader from '@/components/PageHeader.vue' // 页头组件  
import NavBar from '@/components/NavBar.vue' // 导航栏组件  
//import DataDisplay from '@/components/DataDisplay.vue' // 数据展示组件  

export default {
  name: 'HomePage',
  components: {
    // 注册组件，以便在模板中使用  
    PageHeader,
    NavBar,
    //DataDisplay
  },
  data() {
    return {
      //data: [], // 原始数据  
      //filteredData: [], // 筛选后的数据  
      //pageSize: 40, // 每页显示的数据条数  
      //currentPage: 1, // 当前页码  
      //total: 0, // 数据总数  
      //activeNav: 'Option 1', // 当前激活的导航选项  
    };
  },
  methods: {
    // 处理导航点击事件，更新筛选条件和当前页  
    handleNavClick(navName) {
      this.$router.push({ name: navName.name});// , query: { skipAuth: 'true' }
      // this.activeNav = option;
      // this.filteredData = this.data.filter(item => item.category === option);
      // this.total = this.filteredData.length;
      // this.currentPage = 1;
    },
    // 处理页码变化事件（此处方法已注释，如有需要可取消注释）  
    //handlePageChange(page) {
    //  this.currentPage = page;
    //},
    // 模拟数据请求，初始化数据  
    fetchData() {
      // 模拟数据请求  
      //this.data = [
        // ...你的数据  
      //];
      //this.filteredData = this.data;
      //this.total = this.filteredData.length;
    }
  },
  mounted() {
    // 组件挂载后，获取数据  
    this.fetchData();
  }
};  
</script>

<style scoped>
main.el-main:nth-child(2) {
  padding-left: 0px;
  padding-right: 0px;
}

.el-divider--horizontal {
  --el-border-style: solid;
  margin-top: 20px;
  margin-bottom: -5px;
}


</style>