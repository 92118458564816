<template>
    <el-row :gutter="6" style="width: 750px; padding-top: 20px;">
        <el-col :span="1"></el-col>
        <el-col :span="8">
            <el-input v-model="serialNumber" maxlength="12" placeholder="输入12位接头号" show-word-limit type="text" />
        </el-col>
        <el-col :span="4">
            <el-button type="primary" @click="fetchData">查询</el-button>
        </el-col>
    </el-row>
    <el-row :gutter="0" style="width: 1200px">
        <el-col :span="1"></el-col>
    </el-row>

    <el-row :gutter="0" style="width: 1200px">
        <el-col :span="1"></el-col>
        <el-col :span="23">
            <el-table :data="filterTableData">
                <el-table-column v-for="column in columns" :key="column.prop" :prop="column.prop" :label="column.label"
                    :width="column.width" header-align="center" align="center" size="small" />
                <el-table-column>
                    <template #header>
                        <el-input v-model="search" size="small" placeholder="VIN筛选" />
                    </template>

                    <template #default="scope">
                        <el-button size="small" type="danger" @click="handleDownLoad(scope.row)">DownLoad</el-button>
                    </template>

                </el-table-column>
            </el-table>
        </el-col>
    </el-row>
</template>

<script>
import { ref, computed } from 'vue';
import { Edit } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getOutCodebySn,downloadOutCodebyId } from '../api/users';
export default {
    setup() {
        // 列配置，这里你可以根据需要动态设置或静态定义  
        const serialNumber = ref('');
        const search = ref('');
        const columns = ref([
            { prop: 'vin', label: 'vin', width: '180' },
            { prop: 'info', label: 'outcodeinfo', width: '600' },
            { prop: 'time', label: 'Time', width: '180' },
        ]);   

        const tableData = ref([]);

        const filterTableData = computed(() =>
            tableData.value.filter(
                (data) =>
                    !search.value ||
                    data.vin.toLowerCase().includes(search.value.toLowerCase())
            )
        );

        const fetchData = async () => {
            if(serialNumber.value.length !== 12){
                ElMessage.error('请输入正确的序列号!');
                return;
            }

            try {
                // 加载列配置 ，表格数据
                const serialNumberValue = serialNumber.value;
                getOutCodebySn(50,serialNumberValue)
                    .then(response => {
                        // 处理登录成功的响应                      
                        columns.value = response.data.columns;
                        if(response.data.isnull){
                            ElMessage.success('已查询,无该接头相关记录!');
                        }         
                        else{
                            tableData.value = response.data.tableData;
                            ElMessage.success('查询成功！');                
                        }                
                    })
                    .catch(error => {
                        // 处理登录失败的响应  
                        ElMessage.error('查询失败！');
                    });
            } catch (error) {
                ElMessage({ type: 'error', message: '查询失败，请稍后重试' });
            }
        };

        const handleDownLoad = async (row) => {
            try {
                const value = row.id;
                await downloadOutCodebyId(value);
            } catch (error) {
                ElMessage({ type: 'warning', message: '请求资源失败，请重试', })
            }
        };

        // 返回响应式数据和函数，使它们在模板中可用  
        return {
            search,
            columns, filterTableData,tableData,
            Edit,
            ElMessage, ElMessageBox,
            serialNumber,fetchData,
            handleDownLoad
        };
    }
};
</script>



<style scoped>
::v-deep .el-table .cell {
  white-space:pre-wrap;
}
</style>