<template>
    <el-container class="main-container">
        <el-main class="data-container">

            <div class="serial-number-container">
                <el-autocomplete v-model="serialNumber" :fetch-suggestions="querySearch" clearable
                    class="inline-input w-100" placeholder="Please input vin" @select="handleSelect" maxlength="17"
                    show-word-limit type="text" />
                <el-button type="primary" @click="fetchData">查询</el-button>
            </div>

            <el-tabs v-model="radio1" class="demo-tabs" @tab-click="handleClick">
                <el-tab-pane label="CN Server" name="CN Server"></el-tab-pane>
                <el-tab-pane label="US Server" name="US Server"></el-tab-pane>

                <el-row v-loading="loading">
                    <el-col :span="4">
                        <el-statistic title="收集总数" :value="collectTotal" />
                    </el-col>
                    <el-col :span="4">
                        <el-statistic title="未下载总数" :value="notDownloaded" />

                        <el-popconfirm title="点击下载未爬取的所有VIN数据" @confirm="handleDownLoadVin()">
                            <template #reference>
                                <el-button size="small" type="" :icon="Download" circle />
                            </template>
                        </el-popconfirm>

                    </el-col>
                    <el-col :span="4">
                        <el-statistic title="已爬取总数" :value="downloads" />
                    </el-col>
                    <el-col :span="4">
                        <el-statistic title="数据库容量" :value="dbcapacity" />
                    </el-col>
                    <el-col :span="4">
                        <el-statistic title="今日收集" :value="todayCollect" />
                    </el-col>
                    <el-col :span="4">
                        <el-statistic title="今日爬取" :value="todayUpdate" />
                    </el-col>
                </el-row>

                <el-row> <el-col :span="24"></el-col></el-row>
                <el-row :gutter="0" style="margin-top: 15px;">

                    <el-table :data="filterTableData" v-loading="loading2">
                        <el-table-column v-for="column in columns" :key="column.prop" :prop="column.prop"
                            :label="column.label" :width="column.width" header-align="center" align="center" />
                        <el-table-column>
                            <template #header>
                                <el-input v-model="search" size="small" placeholder="VIN筛选" />
                            </template>
                            <template #default="scope">
                                <!-- <el-button size="small" @click="handleEdit(scope.$index, scope.row)">Edit</el-button> -->
                                <el-popconfirm title="下载VM文件" @confirm="handleDownLoad(scope.$index, scope.row)">
                                    <template #reference>
                                        <el-button size="small" type="danger">DownLoad</el-button>
                                    </template>
                                </el-popconfirm>

                                <el-popconfirm title="爬取数据" @confirm="handleRequest(scope.$index, scope.row)">
                                    <template #reference>
                                        <el-button size="small" type="success" :loading="btLoading">Request</el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>

            </el-tabs>
        </el-main>
    </el-container>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { ElMessage } from 'element-plus'
import { getVinInfo, downloadVinInfobyId, getVinStatus, requestVindata,DownLoadVM } from '../api/users';
import { Download } from '@element-plus/icons-vue';
export default {
    setup() {
        // 列配置，这里你可以根据需要动态设置或静态定义  
        const columns = ref([
            { prop: 'Vin', label: 'Vin', width: '200' },
            { prop: 'status', label: '状态', width: '100' },
            { prop: 'time', label: '时间', width: '180' },
            { prop: 'message', label: '信息' },
        ]);
        const tableData = ref([]);
        const search = ref('');
        const filterTableData = computed(() =>
            tableData.value.filter(
                (data) =>
                    !search.value ||
                    data.Vin.toLowerCase().includes(search.value.toLowerCase())
            )
        );
        const cityData = ref({
            'CN Server': [],
            'US Server': []
        });

        const radio1 = ref('CN Server')
        const collectTotal = ref(0); // 收集总数  
        const notDownloaded = ref(0); // 未下载总数  
        const todayCollect = ref(0); // 今日收集  
        const todayUpdate = ref(0); // 今日更新  
        const downloads = ref(0); // 已下载总数  
        const dbcapacity = ref(''); // 数据库容量

        const loading = ref(false)  // 定义 按钮请求网络时等待的 状态
        const loading2 = ref(false)
        const btLoading = ref(false)
        const serialNumber = ref('');
        const currentPage = ref(1);
        const pageSize = ref(20); // 每页显示N条数据为例  

        const isValidLength = ref(true);
        //const recentQueries = ref([]);
        const recentQueries = ref(JSON.parse(localStorage.getItem('recentQueriesVinList')) || []);

        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        const paginatedData = computed(() => {
            const start = (currentPage.value - 1) * pageSize.value;
            const end = currentPage.value * pageSize.value;
            return cityData.value[radio1.value].slice(start, end);
        });

        //相应函数
        const handleClick = async (tab, event) => {
            loading.value = true;
            const useBaseURL = (tab.paneName === 'CN Server') ? true : false;
            const response = await getVinInfo(useBaseURL);

            collectTotal.value = response.data.collectTotal;
            notDownloaded.value = response.data.notDownloaded;
            todayCollect.value = response.data.todayCollect;
            todayUpdate.value = response.data.todayUpdate;
            downloads.value = response.data.downloads;
            dbcapacity.value = response.data.dbcapacity;
            loading.value = false;
        }

        // 组件挂载时发起请求  
        onMounted(async () => {
            loading.value = true;
            const response = await getVinInfo(true);
            collectTotal.value = response.data.collectTotal;
            notDownloaded.value = response.data.notDownloaded;
            todayCollect.value = response.data.todayCollect;
            todayUpdate.value = response.data.todayUpdate;
            downloads.value = response.data.downloads;
            dbcapacity.value = response.data.dbcapacity;
            loading.value = false;
        });

        const handleDownLoadVin = async () => {
            try {
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                await downloadVinInfobyId(useBaseURL);//下载文件
            } catch (error) {
                ElMessage({ type: 'error', message: '请求资源失败，请重试', })
            }
        };

        const handleRequest = async (index, row) => {
            try {
                if (row.status == "OK") {
                    return ElMessage({ type: 'error', message: '数据已存在,无需爬取!!!', });
                }

                const value = row.Vin;
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                btLoading.value = true;
                await requestVindata(useBaseURL, value);
                ElMessage({ type: 'success', message: '已发送请求,建议在20后再次查询结果', })
                await delay(20000);//20秒
            } catch (error) {
                if (error.status == 400) {
                    ElMessage({ type: 'error', message: '未登录账号!!!', })
                }
                else {
                    ElMessage({ type: 'error', message: '请求资源失败，请重试', })
                }
            }
            finally {
                btLoading.value = false;
            }
        };

        const handleDownLoad = async (index, row) => {
            try {
                if (row.status != "OK") {
                    return ElMessage({ type: 'error', message: '数据不存在,请先完成Request操作', });
                }

                const value = row.Vin;
                const useBaseURL = (radio1.value === 'CN Server') ? true : false;
                
                await DownLoadVM(useBaseURL, value);

                ElMessage({ type: 'success', message: '下载成功', })
            } catch (error) {
                ElMessage({ type: 'error', message: '请求资源失败，请重试', })
            }
        };

        const fetchData = async () => {
            const serialNumberValue = (serialNumber.value && serialNumber.value.length === 17) ? serialNumber.value : "all";
            const useBaseURL = (radio1.value === 'CN Server') ? true : false;
            if (!serialNumber.value || serialNumber.value.length !== 17) {
                ElMessage({ type: 'error', message: '请输入一个17位的Vin', })
                return;
            }
            loading2.value = true;  //开始加载
            const response = await getVinStatus(useBaseURL, serialNumberValue);
            if (response.data.isnull) {
                ElMessage({ type: 'warning', message: '服务器无该VIN数据', });
            }
            else {
                columns.value = response.data.columns;
                tableData.value = response.data.tableData;
            }

            try {
                if (serialNumber.value.length === 17) {
                    updateRecentQueries(serialNumberValue);
                }
            } catch (error) {
                alert('请求失败，请重试');
            } finally {
                loading2.value = false;
            }
        };
        // 更新查询记录的函数  


        const updateRecentQueries = (query) => {
            // 检查查询是否已存在于数组中，注意这里要比较对象的 value 属性  
            const index = recentQueries.value.findIndex(item => item.value === query);
            if (index !== -1) {
                // 如果已存在，则将其从数组中移除  
                recentQueries.value.splice(index, 1);
            }
            // 将查询（作为对象）添加到数组头部，并确保数组长度不超过3
            recentQueries.value.unshift({ value: query }); // 使用unshift而不是push  
            if (recentQueries.value.length > 3) {
                recentQueries.value.pop(); // 使用pop移除末尾元素，而不是shift  
            }

            localStorage.setItem('recentQueriesVinList', JSON.stringify(recentQueries.value));
        };

        // 在 handleSelect 函数中，用户选择建议后更新最近查询记录  
        const handleSelect = (item) => {
            updateRecentQueries(item.value);
            // ... 其他处理选择的代码 ...  
        };


        // 将 recentQueries 添加到 fetch-suggestions 中  
        const querySearch = (queryString, cb) => {

            cb(recentQueries.value);
        };
        return {
            columns, tableData,
            filterTableData,
            currentPage,
            pageSize,
            paginatedData,
            serialNumber, recentQueries,
            isValidLength,
            fetchData,
            loading, loading2, btLoading,
            radio1,
            cityData,
            querySearch, search,
            handleSelect,
            handleClick, handleDownLoad,
            Download, handleDownLoadVin, handleRequest,
            collectTotal, notDownloaded, todayCollect, todayUpdate, downloads, dbcapacity
        };
    },
};
</script>

<style scoped>
/* 数据显示栏的样式 */
.main-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.header-container {
    text-align: center;
}

.el-table {
    border: 1px solid #ebeef5;
    border-radius: 4px;
}

.el-table th,
.el-table td {
    text-align: left;
    padding: 12px 20px;
}

.serial-number-container {
    width: 350px;
    /* margin-top: 1px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}

.el-col {
    text-align: center;
}
</style>