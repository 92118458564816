import { createApp } from 'vue'
//通常，App.vue 是 Vue 项目的入口组件，它包含了应用的根级模板、样式和逻辑。
//Vue 组件是 Vue 应用的基本构建块。
//每个组件都是一个包含模板（template）、脚本（script）和样式（style）的独立单元。
//组件可以嵌套在其他组件中，以构建出复杂的应用界面。
import App from './App.vue'
//Vue Router 用于为 Vue 应用中的不同页面或视图创建导航。
//它允许你定义路由（即 URL 路径与组件之间的映射关系），并通过这些路由来管理应用的不同页面或状态。
//当用户访问某个 URL 时，Vue Router 会根据定义的路由将相应的组件渲染到视图中。
import router from './router'
//Vuex store 是一个用于集中管理应用中的所有组件的状态的容器。
//它包含了应用的状态（state）、改变状态的方法（mutations）、以及可以异步调用这些方法的行为（actions）。
//通过将状态集中管理，Vuex 可以帮助我们更好地管理组件之间的共享状态，以及状态变更的逻辑。
import store from './store'
//自定义请求，应答拦截器
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App).use(store).use(router).use(ElementPlus);  

// 将 axiosInstance 注册为全局属性  
//app.config.globalProperties.$axios = axiosInstance;  


app.mount('#app');

