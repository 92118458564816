import axios from "axios";  
import router from '../router';

// 错误处理函数  
const errorHandle = (status, info) => {  
    // 可以在这里添加更多的错误处理逻辑  
    console.error(`Error Status: ${status}`);  
    console.error(`Error Info: ${info}`);  
    // 根据错误状态码执行不同的操作  
    switch (status) {  
        case 400:  
            console.error("Bad Request: 语义有误");  
            break;  
        case 401:  
            console.error("Unauthorized: 未授权");  
            // 可以在这里执行退出登录或其他操作  
            router.push('/login'); 
            break;  
        case 403:  
            console.error("Forbidden: 拒绝访问");  
            break;  
        case 404:  
            console.error("Not Found: 资源未找到");  
            break;  
        // 添加更多的状态码处理逻辑...  
        default:  
            console.error(`其他错误: ${info}`);  
            break;  
    }  
};  
  
// 创建 Axios 实例  
const instance = axios.create({  
    // 网络请求公共配置  
    timeout: 10 * 1000,  
});  
  
// 添加请求拦截器  
instance.interceptors.request.use(  
    config => {  
        if (config.method === "post") {  
            //config.data = qs.stringify(config.data, { addQueryPrefix: true })
            //console.log(config.data);
        }  
  
        // 在发送请求之前添加 token 到请求头  
        const token = localStorage.getItem("authToken");  
        if (token) {  
            config.headers["Authorization"] = `Bearer ${token}`;  
        }  
  
        return config;  
    },  
    error => {  
        // 请求错误处理  
        return Promise.reject(error);  
    }  
);  
  
// 添加响应拦截器  
instance.interceptors.response.use(  
    response => {  
        // 如果状态码是 200，则返回响应数据  
        if (response.status === 200) {  
            return Promise.resolve(response);  
        } 
        else {  
            // 如果状态码不是 200，则返回一个包含错误信息的 Promise 拒绝对象  
            return Promise.reject({  
                status: response.status,  
                info: response.statusText || "Unknown error"  
            });  
        }  
    },  
    error => {  
        // 响应错误处理  
        const { response } = error;  
        let status = response ? response.status : 500;  
        let info = response ? (response.data && response.data.message ? response.data.message : response.statusText) : "Network Error";  
  
        // 调用错误处理函数  
        errorHandle(status, info);  
  
        // 返回一个包含错误信息的 Promise 拒绝对象  
        return Promise.reject({ status, info });  
    }  
);  
  
export default instance;