<template>
  <el-container class="main-container">
    <el-main class="data-container">

      <div class="serial-number-container">
        <el-autocomplete v-model="serialNumber"
          :fetch-suggestions="(queryString, cb) => querySearch(queryString, cb, 'sn')" clearable
          class="inline-input w-210" placeholder="Please input sn" @select="handleSelectSn" maxlength="12"
          show-word-limit type="text" style="width: 210px" />
        <el-button borderType="outline" type="primary" plain @click="fetchData">提交</el-button>

        <el-autocomplete v-model="myvin" :fetch-suggestions="(queryString, cb) => querySearch(queryString, cb, 'vin')"
          clearable class="inline-input w-260" placeholder="Please input vin" @select="handleSelectVin" maxlength="17"
          show-word-limit type="text" style="width: 260px" />
        <el-button borderType="outline" type="primary" plain @click="fetchDataVin">提交</el-button>
      </div>

      <div style="margin-top: 5px">
        <el-radio-group v-model="radio1">
          <el-radio-button label="CN Server" value="CN Server" />
          <el-radio-button label="US Server" value="US Server" />
        </el-radio-group>
      </div>

      <el-table :data="paginatedData" stripe border style="width: 100%" height="600" v-loading="loading">
        <el-table-column v-for="column in columns" :key="column.prop" :prop="column.prop" :label="column.label"
          :width="column.width" header-align="center" />
      </el-table>

      <el-pagination class="pagination-container" background layout="prev, pager, next" :total="total"
        :page-size="pageSize" @current-change="handlePageChange">
      </el-pagination>

    </el-main>

  </el-container>
</template>

<script>
import { ref, computed } from 'vue';
import { getPageInfo, getPageInfoVin } from '../api/users';

export default {
  setup() {
    // 列配置，这里你可以根据需要动态设置或静态定义  
    const columns = ref([
      { prop: 'SnType', label: '用户类型', width: '65' },
      { prop: 'Version', label: '版本', width: '80' },
      { prop: 'Vin', label: 'Vin', width: '180' },
      { prop: 'DiagParam', label: '请求参数', width: '250' },
      { prop: 'ResData', label: '响应数据', width: '450' },
      { prop: 'FuncType', label: '功能类型', width: '60' },
      { prop: 'CreatedAt', label: '日志时间', width: '110' },
      { prop: 'DiagId', label: '软件ID', width: '120' },
      { prop: 'Sn', label: '序列号', width: '125' },
      { prop: 'runtime', label: '执行时间', width: '80' },
      { prop: 'Ip', label: 'Ip', width: '135' },
      { prop: 'Language', label: '语言', width: '60' },
      { prop: 'ProductType', label: '产品类型', width: '60' },
      { prop: 'LocalMac', label: 'LocalMac', width: '120' },
      { prop: 'WifiMac', label: 'WifiMac', width: '120' },
      { prop: 'WifiName', label: 'WifiName', width: '160' }
    ]);

    const cityData = ref({
      'CN Server': [],
      'US Server': []
    });

    const radio1 = ref('CN Server')
    const loading = ref(false)  // 定义 按钮请求网络时等待的 状态
    const serialNumber = ref('');
    const myvin = ref('');
    const currentPage = ref(1);
    const pageSize = ref(20); // 每页显示N条数据为例  
    const total = computed(() => cityData.value[radio1.value].length);
    const isValidLength = ref(true);
    //const recentQueries = ref([]);
    const recentQueries = ref(JSON.parse(localStorage.getItem('recentQueries')) || []);

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * pageSize.value;
      const end = currentPage.value * pageSize.value;
      return cityData.value[radio1.value].slice(start, end);
    });

    //相应函数
    const handlePageChange = (page) => {
      currentPage.value = page;
    };

    //const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const fetchData = async () => {
      const serialNumberValue = (serialNumber.value && serialNumber.value.length === 12) ? serialNumber.value : "all";
      const useBaseURL = (radio1.value === 'CN Server') ? true : false;
      if (!serialNumber.value || serialNumber.value.length !== 12) {
        //alert('请输入一个12位的序列号');
        //return;
      }
      loading.value = true;  //开始加载
      try {
        //await delay(200);//设置延迟,速度太快反而没有感知

        const response = await getPageInfo(100, serialNumberValue, useBaseURL);
        //服务器返回的数据结构为 { columns: [...], tableData: [...] }  
        columns.value = response.data.columns;
        cityData.value[radio1.value] = response.data.tableData;
        if (serialNumber.value.length === 12) {
          updateRecentQueries("sn", serialNumberValue);
        }
      } catch (error) {
        alert('请求失败，请重试');
      } finally {
        loading.value = false;
      }
    };

    const fetchDataVin = async () => {
      const myvinValue = myvin.value;
      const useBaseURL = (radio1.value === 'CN Server') ? true : false;
      if (!myvin.value || myvin.value.length !== 17) {
        alert('请输入一个17位的VIN');
        return;
      }
      updateRecentQueries("vin", myvinValue);
      loading.value = true;  //开始加载
      try {
        const response = await getPageInfoVin(100, myvinValue, useBaseURL);
        //服务器返回的数据结构为 { columns: [...], tableData: [...] }  
        columns.value = response.data.columns;
        cityData.value[radio1.value] = response.data.tableData;
        if (myvin.value.length === 17) {
          updateRecentQueries("vin", myvinValue);
        }
      } catch (error) {
        alert('请求失败，请重试');
      } finally {
        loading.value = false;
      }
    };
    // 更新查询记录的函数  


    const updateRecentQueries = (type, query) => {
      // 检查查询是否已存在于数组中，注意这里要比较对象的 value 属性  
      const index = recentQueries.value.findIndex(item => item.value === query);
      if (index !== -1) {
        // 如果已存在，则将其从数组中移除  
        recentQueries.value.splice(index, 1);
      }
      // 将查询（作为对象）添加到数组头部，并确保数组长度不超过3
      recentQueries.value.unshift({ type: type, value: query }); // 使用unshift添加到数组头部

      if (recentQueries.value.length > 3) {
        recentQueries.value.pop(); // 使用pop移除末尾元素，而不是shift  
      }
      localStorage.setItem('recentQueries', JSON.stringify(recentQueries.value));
    };

    // 在 handleSelect 函数中，用户选择建议后更新最近查询记录  
    const handleSelectSn = (item) => {
      updateRecentQueries("sn", item.value);
    };
    const handleSelectVin = (item) => {
      updateRecentQueries("vin", item.value);
    };


    // 将 recentQueries 添加到 fetch-suggestions 中  
    const querySearch = (queryString, cb, type) => {
      const suggestions = recentQueries.value.filter(query => query.type === type);
      cb(suggestions);
    };

    return {
      columns,
      currentPage,
      pageSize,
      total,
      paginatedData,
      serialNumber,
      myvin,
      isValidLength,
      handlePageChange,
      fetchData,
      fetchDataVin,
      loading,
      radio1,
      cityData,
      querySearch,
      handleSelectSn,
      handleSelectVin
    };
  },
};  
</script>

<style scoped>
/* 数据显示栏的样式 */
.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.header-container {
  text-align: center;
}

/* .data-container {  
  flex: 1;  
  padding: auto;  
  overflow-y: auto;  
}   */

.pagination-container {
  margin-top: 20px;
  text-align: right;
}

.el-table {
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.el-table th,
.el-table td {
  text-align: left;
  padding: 12px 20px;
}

.serial-number-container {
  width: 600px;
  /* margin-top: 1px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.data-container {
  padding-top: 0px;
}
</style>